<template>
  <div class="container-center-horizontal container-center-horizontal-mobile">
    <div class="header-group-navigation">
      <navigation2 :logo="require('@/assets/images/header/logo-1@2x.png')" navbarLinkProducts="Products"
        navbarLinkResources="Resources" navbarLinkCompany="Company" navbarLinkPartnerships="Partnerships"
        buttonProps="Login" button2Props="SignUp" />
    </div>

    <div class="header-group-navigation-spacer" />
    <!--    home and title-->
    <HomeSection />
    <BannerLogos />
    <!-- TrustPilot widget -->
    <div class="trustpilot-container">
      <h2 class="trustpilot--header">Don't just take our word for it</h2>
      <p class="trustpilot--subheader">See what Gainbridge® customers have to say</p>
      <div
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="653abf452634c98aa8635866"
        data-style-height="140px"
        data-style-width="100%"
        data-tags="SelectedReview"
        data-review-languages="en"
        data-text-color="#020102">
          <a href="https://www.trustpilot.com/review/www.gainbridge.io" target="_blank" rel="noopener">
            Trustpilot
          </a>
      </div>
    </div>
    <!--    trust icons-->
    <div class="container home screen">
      <div class="home-content-container trust-container">
        <div class="icon-inner-row-home">
          <div class="trust-content-container">
            <h2 class="trust-content-title-header">Your trust means everything</h2>
            <p class="trust-content-title-text">We'll always be here for you.</p>
          </div>
          <div class="trust-row">
            <div class="trust-row-section">
              <img :src="require('@/assets/images/home/trust-icon1.svg')" alt="Vector" />
              <h3 class="trust-title-home">40% repeat customers</h3>
              <p class="trust-text-home">Our customers just keep coming back to us. </p>
            </div>
            <div class="trust-row-section">
              <img :src="require('@/assets/images/home/trust-icon2.svg')" alt="Vector" />
              <h3 class="trust-title-home">{{ aumAmount }}</h3>
              <p class="trust-text-home">We are a subsidiary of Group1001, which has {{ aumAmount }} of combined assets
                under management as of {{ aumDate }}.</p>
            </div>
            <div class="trust-row-section">
              <img :src="require('@/assets/images/home/trust-icon3.svg')" alt="Vector" />
              <h3 class="trust-title-home">A- (Excellent) rated</h3>
              <p class="trust-text-home">We offer products on our platform from insurers rated A- (Excellent) by AM
                Best<a href="#footnote-home-4"><sup>4</sup></a> for their financial strength.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gainbridge protect/mylo -->
    <GainbridgeProtect :href="protectLink" />

    <!-- images and text main content -->
    <div class="container home screen values-based-org">
      <div class="home-content-container">
        <div class="image-content-container">
          <h2 class="image-content-title-header">We are a values based organization</h2>
          <p class="image-content-title-text">We care about what you care about.</p>
        </div>


        <RightImageContent title-text="Opportunity through access"
          content-text="At Gainbridge, we design robust, easy-to-use, result-driven products.
                           We did this through low-investment minimums to democratize financial product access for everyone."
          :src-image="require('@/assets/images/home/home-1.jpg')" icon-title="Projected Growth" icon-body="$10,628"
          :icon-src="require('@/assets/images/home/image-icon-1.png')" />


        <LeftImageContent title-text="Strong guiding principles" content-text="We are guided by a strong sense of integrity. This governs every aspect of our
                          decision-making, from how we invest in our portfolios to hiring people on our teams."
          :src-image="require('@/assets/images/home/home-2.jpg')" icon-title="Community donations"
          icon-body="$1,000,000+" :icon-src="require('@/assets/images/home/image-icon-2.png')" />

        <RightImageContent title-text="Empowering teams and communities" content-text="We are on a mission to transform communities through sports and education.
                           It's in our DNA. Our partnerships, including the world-famous Billie Jean King Cup in women's
                           tennis, address important initiatives such as the gender equity gap in sports."
          :src-image="require('@/assets/images/home/home-3.jpg')" icon-title="Team sponsorships" icon-body="100+ events"
          :icon-src="require('@/assets/images/home/image-icon-3.png')" />

      </div>
    </div>

    <div class="container">
      <FloatingModule background-color="linear-gradient(to bottom left, #EFC21E ,#EE6F00)"
        :wantToTakeControl="floatingModuleProps.wantToTakeControl" bar-button-color="#FF7700"
        :href-link="enrollmentLink" button-text="Get Started" />

    </div>


    <div class="add-space-for-footer"></div>
    <Footer />

  </div>
</template>

<script>
import FloatingModule from "@/components/FloatingModule.vue";
import MyCarousel from '@/components/MyCarousel.vue';
import Navigation2 from "@/components/header/Navigation2.vue";
import ContentTextHome from "@/components/product/ContentTextHome.vue";
import LeftImageContent from "@/pages/home/components/LeftImageContent.vue";
import RightImageContent from "@/pages/home/components/RightImageContent.vue";

import HomeBanner from "@/pages/home/components/HomeBanner.vue";
import Footer from "./Footer.vue";
import GainbridgeProtect from "./GainbridgeProtect.vue";
import HomeSection from "./HomeSection.vue";
import BannerCard from "./bannercard/BannerCard";
import BannerLogos from "./bannerlogos/BannerLogos";
import { getEnrollmentUrl, saveUTMParams } from "../../../components/utils";
import { cookieConsent } from "../../../cookieConsent";
import { aumAmount, aumDate } from "@/aum";

export default {
  name: "Home",
  mounted: () => {
    saveUTMParams();
    cookieConsent();
  },
  data() {
    return {
      aumAmount,
      aumDate,
      slides: [
        {
          id: 1,
          title: 'Larry',
          description: 'Connecticut',
          bodyText: '“Unbeatable rates and great customer service. For a safe investment, this is as good as it\'s going to get in this environment.”'
        },
        {
          id: 2,
          title: 'Anonymous customer',
          description: '2021 Gainbridge customer survey',
          bodyText: '“I am all annuities...I don’t want to risk in the stock market. I have a couple that will be rolling off in about a year and a half.”'
        },
        {
          id: 3,
          title: 'Anonymous customer',
          description: '2021 Gainbridge customer survey',
          bodyText: '“Your 3% penalty is very attractive. The majority [of carriers] do a tiered thing. This is beneficial.”'
        },
        {
          id: 4,
          title: 'Anonymous customer',
          description: '2021 Gainbridge customer survey',
          bodyText: '“I was pleased at the ability to easily chat with a representative online, who answered all of my questions. Annuity rate is good for this time period.”'
        },
        {
          id: 5,
          title: 'Robert',
          description: 'Florida',
          bodyText: '“Everything was just so easy to do”'
        },
        {
          id: 6,
          title: 'Brian',
          description: 'Illinois',
          bodyText: '“Gainbridge is a name to watch in the annuity world. Unlike some internet-only financial companies, Gainbridge makes it easy to set up and fund an annuity.”'
        },
        {
          id: 7,
          title: 'Chris',
          description: 'Massachusetts',
          bodyText: '“It’s such a good idea and long overdue.”'
        },
      ],
      steadyGrowthLink: "steadypace.html",
      balancedGrowthLink: "parityseries.html",
      acceleratedGrowthLink: "oneup.html",
      partnershipsLink: "partnerships.html",
      protectLink: "protect.html",
      fastbreakLink: "fastbreak.html",
      parityflexLink: "parityflex.html",
      enrollmentLink: getEnrollmentUrl(),
      titleExtra: "money",
      cycleCount: 1,
      titleWords: ['money', 'investment', 'future', 'wealth'],
      LAUNCH_DARKLY_KEY_VAR: process.env.LAUNCH_DARKLY_KEY
    }
  },
  components: {
    BannerCard,
    BannerLogos,
    ContentTextHome,
    FloatingModule,
    Footer,
    GainbridgeProtect,
    HomeBanner,
    HomeSection,
    LeftImageContent,
    MyCarousel,
    Navigation2,
    RightImageContent,
  },
  props: [
    "floatingModuleProps",
  ],
};
</script>

<style>
.header-group-navigation {
  height: 80px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header-group-navigation-spacer {
  height: 148px;
  background-color: #FAF7F4;
}

@media (min-width: 802px) {
  .header-group-navigation-spacer {
    height: 112px;
  }
}

.values-based-org {
  padding-top: 60px;
}

@media (min-width: 900px) {
  .values-based-org {
    padding-top: 116px;
  }
}

.home-content-container {
  width: 100%;
}

.trust-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.learn-more-link {
  text-decoration: none;
  font-weight: 700;
}

.trust-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.trust-row-section {
  display: flex;
  flex-direction: column;
  max-width: 330px;
}

.trust-row-section img {
  width: 60px;
  height: 60px;
}

.trust-icon-container {
  padding: 10px;
  border-radius: 9000px;
  border: 10px solid
}

.trust-title-home {
  letter-spacing: 0;
  line-height: 40.96px;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  margin-top: 24px;
}

.trust-text-home {
  letter-spacing: 0;
  line-height: 35px;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: start;
}

.trustpilot-container {
  display: flex;
  padding: 0 36px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 74px;
}

.trustpilot--header {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.trustpilot--subheader {
  font-size: 32px;
  text-align: center;
  color: #434A55;
}

.trustpilot-widget {
  margin-top: 60px;
  width: 100%;
  max-width: 1296px;
}

.trust-text-home a {
  color: var(--black);
}

.icon-inner-row-home {
  padding: 116px 24px;
  max-width: 1320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.carousel-header-title {
  text-align: center;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-weight: 700;
  line-height: 40.96px;
  color: #FFFFFF;
  margin-bottom: 3px;
}

.each-icon-home-trust {
  position: relative;
  /*max-width: 454px;*/
  margin-top: 30px !important;
}

.foot-note {
  display: flex;
  margin-top: 100px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 12px;
  color: var(--black);
  align-content: center;
  flex-wrap: wrap;
}

.carousel-row-home {
  position: relative;
  left: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 31px;
  min-height: 293px;
  padding: 120px 0px 110px 0px;
  margin-bottom: 100px !important;
  margin: 0 -9999rem;
  background-color: #0F68F1;
}


@media (max-width: 768px) {
  .carousel-row-home {
    padding: 90px 0px 70px 0px;
  }
}

.title-home {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 72px;
  font-weight: 1000;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
  display: flex;
  gap: 7px;

  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 40px;
  font-weight: 1000;
  /*left: 172px;*/
  letter-spacing: 0;
  line-height: 45px;
  position: relative;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.title-home-container {
  max-width: 500px;
  margin-right: 165px;
}

.title-rate {
  display: flex;
}

.image-content-container {
  width: 100%;
}

.image-content-title-header {
  justify-content: center;
  display: flex;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-weight: 700;
  font-size: 48px;
  line-height: 61.44px;
  color: var(--black);
}

.image-content-title-text {
  justify-content: center;
  display: flex;
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #434A55;
}

.trust-content-container {
  width: 100%;
}

.trust-content-title-header {
  justify-content: center;
  display: flex;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-weight: 700;
  font-size: 48px;
  line-height: 61.44px;
  color: var(--black);
}

.trust-content-title-text {
  justify-content: center;
  display: flex;
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #434A55;
}

.add-space-for-footer {
  margin-top: 90px;
}

.header-spacing-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 180px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56px;
}


@media (max-width: 991.98px) {
  .trust-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .trust-content-title-header {
    font-size: 40px;
    text-align: center;
  }

  .trust-content-title-text {
    justify-content: left;
    font-size: 20px;
  }

  .header-spacing-home {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 150px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .trust-text-home {
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    color: var(--black);
    font-family: var(--font-family-aktiv_grotesk-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    margin-right: 0px;
  }


  .trust-row {
    flex-direction: column;
    align-items: center;
  }

  .trust-row-section {
    flex-direction: column;
    align-items: center;
  }


  .icon-inner-row-home {
    padding: 24px;
    padding-top: 60px;
    gap: 24px;
  }

  .header-spacing-home h1:nth-of-type(2)::after {
    width: 3px;
    height: 40px;
  }

  .image-content-title-header {
    justify-content: flex-start;
    display: flex;
    font-family: var(--font-family-aktiv_grotesk-bold);
    font-weight: 700;
    font-size: 40px;
    line-height: 51.2px;
  }

  .image-content-title-text {
    justify-content: flex-start;
    display: flex;
    font-family: var(--font-family-aktiv_grotesk-regular);
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    margin-bottom: -80px;
  }

  .trust-title-home {
    letter-spacing: 0;
    line-height: 30.72px;
    position: relative;
    color: var(--black);
    font-family: var(--font-family-aktiv_grotesk-bold);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    text-align: start;
    margin-top: 24px;
    margin-right: 0px;
  }
}

.image-icon-3-home {
  position: absolute;
  width: 45px;
  height: 45px;
}
</style>
