export const xButton2Data = {
  button: "Get started today",
};
export const floatingModuleData = {
  wantToTakeControl: "Take control of your financial future",
  xButton2Props: xButton2Data,
};
export const homeData = {
  floatingModuleProps: floatingModuleData,
};
