<template>
    <div class="navbar-gainbridge-protect-container" v-bind:class="{ hidden: !isVisible }">
        <div class="navbar-gainbridge-protect-content">
            <div class="navbar-gainbridge-protect-text">
                <span class="navbar-gainbridge-protect-new">New!</span>
                <p class="navbar-gainbridge-protect-copy navbar-gainbridge-protect-mobile">Earn a higher rate than most banks.
                </p>
                <p class="navbar-gainbridge-protect-copy navbar-gainbridge-protect-desktop">Earn a higher rate than most banks with FastBreak™.
                </p>
            </div>
            <a class="navbar-gainbridge-learn-more" :href="href">
                Learn more
            </a>
        </div>
        <button class="navbar-gainbridge-close-button" v-on:click="toggleVisible">
            <CloseIcon />
        </button>
    </div>
</template>
  
<script>
import CloseIcon from './CloseIcon.vue';
export default {
    name: "GainbridgeProtect",
    components: { CloseIcon },
    props: ['href'],
    data: () => ({
        isVisible: true
    }),
    methods: {
        toggleVisible: function () {
            this.isVisible = false;
        }
    }
}
</script>
  
<style>
.navbar-gainbridge-protect-container {
    background-color: #0F68F1;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 16px;
    animation: slide-in-mobile 4s forwards;
    transition: 3s;
}

.navbar-gainbridge-protect-content {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.navbar-gainbridge-protect-text {
    display: flex
}

.navbar-gainbridge-protect-mobile {
    display: flex;
}

.navbar-gainbridge-protect-desktop {
    display: none;
}

.navbar-gainbridge-protect-new {
    color: #FFF;
    text-align: center;
    font-family: var(--font-family-aktiv_grotesk-bold);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.navbar-gainbridge-close-button {
    background-color: transparent;
    border: 0
}

.navbar-gainbridge-protect-copy {
    margin: 0;
    color: #FFF;
    text-align: center;
    padding: 0 4px;
    font-family: var(--font-family-aktiv_grotesk-regular);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.navbar-gainbridge-learn-more {
    font-family: var(--font-family-aktiv_grotesk-bold);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    width: fit-content;
    color: #FFFFFF;
    text-decoration-line: none;
}


@media (min-width: 802px) {
    .navbar-gainbridge-protect-container {
        padding: 0 24px;
        animation: slide-in-desktop 4s forwards;
    }

    .navbar-gainbridge-protect-content {
        flex-direction: row;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding: 8px;
        gap: 24px;
    }


    .navbar-gainbridge-protect-mobile {
        display: none;
    }

    .navbar-gainbridge-protect-desktop {
        display: flex;
    }
}

@keyframes slide-in-mobile {
    0% {
        padding: 0 16px;
        height: 0;
    }

    50% {
        padding: 0 16px;
        height: 0;
    }

    100% {
        padding: 16px;
        height: 68px;
    }
}

@keyframes slide-in-desktop {
    0% {
        padding: 0 8px;
        height: 0;
    }

    50% {
        padding: 0 8px;
        height: 0;
    }

    100% {
        padding: 8px;
        height: 32px;
    }
}

.hidden {
    display: none;
}
</style>
