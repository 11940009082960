<template>
  <div class="product-content-text-home">
    <h3 class="col-lg-12 x-your-home aktivgrotesk-bold-black-32px" v-html="Title"></h3>
    <p class="col-lg-12 you-want-flexibility-home aktivgrotesk-regular-normal-black-16px" v-html="Content">
    </p>
    <slot></slot>

  </div>
</template>

<script>
export default {
  name: "ContentTextHome",
  props: ["Title", "Content"],
};
</script>

<style>
.product-content-text-home {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /*left: 170px;*/
  min-height: 202px;
  position: relative;
  /*top: 1368px;*/
  /*width: 542px;*/
}

.x-your-home {
  letter-spacing: 0;
  line-height: normal;
  min-height: 41px;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-style: normal;
  font-weight:700;
  /*width: 538px;*/
}

.you-want-flexibility-home {
  letter-spacing: 0;
  line-height: 24px;
  /*min-height: 146px;*/
  /*width: 538px;*/
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.span-3 {
  line-height: 24px;
}

.span1-1 {
  line-height: 27.2px;
}

.group-7552.group-7552-1 {
  /*left: 172px;*/
  min-height: 157px;
  /*top: 2973px;*/
}

.group-7552.group-7552-1 .you-want-flexibility {
  min-height: 101px;
}
</style>
