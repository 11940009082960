<template>
  <div>
    <carousel :autoplay="true" :autoplay-timeout="5000" :interval="5000" @mouseover="stopAutoScroll"
              @mouseleave="startAutoScroll"
              :loop="true" :transition-mode="'scroll'" :transition-duration="8000" :transition-easing="'linear'"
              :per-page="1" :move="1">
      <slide v-for="slide in slides" :key="slide.id" class="center-card">
        <div class="carousel-content">
          <h2 class="carousel-title">{{ slide.title }}</h2>
          <div class="carousel-id-position"><span>{{ slide.id }} / {{ slides.length }}</span></div>
          <p class="carousel-description">{{ slide.description }}</p>
          <p class="carousel-body-text">{{ slide.bodyText }}</p>
        </div>
      </slide>
    </carousel>
  </div>
</template>


<script>
import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'MyCarousel',
  data() {
    return {
      perPage: 3, // default number of cards per page
    };
  },
  computed: {
    totalItems() {
      return this.slides.length;
    },
  },
  components: {
    Carousel,
    Slide,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    stopAutoScroll() {
      this.$refs.carousel.pauseAutoScroll();
    },
    startAutoScroll() {
      this.$refs.carousel.startAutoScroll();
    },
  },

  // mounted() {
  //   // update the number of cards per page on window resize
  //   window.addEventListener('resize', () => {
  //     if (window.innerWidth <= 576) {
  //       this.perPage = 1;
  //     }
  //     else if (window.innerWidth <= 992) {
  //       this.perPage = 2;
  //     } else {
  //       this.perPage = 3;
  //     }
  //   });
  // },
}
</script>

<style scoped>
.carousel-title {
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-weight: 700;
  font-size: 18px;
  color: var(--black);
  margin-right: 70px;
  margin-bottom: 5px;
}

.carousel-description {
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-weight: 500;
  font-size: 14px;
  color: var(--black-4);
  margin-right: 70px;
  margin-bottom: 13px;
}

.carousel-body-text {
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-weight: 400;
  font-size: 16px;
  color: var(--black-3);
}

.carousel-id-position {
  position: absolute;
  right: 30px;
  top: 30px;
  border-radius: 20px;

  border: 1px solid rgba(128, 128, 128, 0.5);
}

.carousel-id-position > span{
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-weight: 700;
  font-size: 14px;
  color: var(--black-4);

  padding: 0px;
  margin: 8px 15px 6px 15px;

  display: block;
}

.carousel-content {
  border-radius: 3px;
  margin: 20px;
  text-align: start;
  padding: 33px;
  width: 538px;
  min-height: 200px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #FFFFFF;
}

.slide-enter-active,
.slide-leave-active {
  transition: none;
}

.slide-enter {
  opacity: 0;
  transform: translateX(10%);
  position: absolute;
  left: 50%;
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

.slide-move {
  transform: translateX(1px);
}

.center-card {
  position: relative;
  justify-content: center;
  display: flex;
}


@media (max-width: 576px) {
  .carousel-content {
    width: 360px;
  }
}

@media (max-width: 398px) {
  .carousel-content {
    width: 320px;
  }
}

@media (max-width: 320px) {
  .carousel-content {
    width: 280px;
  }
}

</style>
