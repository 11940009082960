<template>
  <div class="start-button-container-1">
    <a class="start-button-link" :href="link">
      <div class="start-button-container-yellow-button yellow-button">
        <div class="start-button-yellow">{{ text }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "StartButton",
  props: [
    "link",
    "text",
  ]
};
</script>

<style>
.start-button-container-1 {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.start-button-link {
  width: 100%;
  text-decoration: none;
}

.start-button-container-yellow-button {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
  width: 100%;
}

.start-button-yellow {
  color: var(--black);
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: -46.5px;
  margin-top: -46.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  font-family: var(--font-family-aktiv_grotesk-bold);
}
</style>
