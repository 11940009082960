<template>
  <div class="gainbridge-protect-container">
    <div class="gainbridge-protect-content">
      <h2 class="gainbridge-protect-header">Gainbridge® Protect</h2>
      <p class="gainbridge-protect-copy">A one-stop shop with insurance expert Mylo that partners with leading carriers to
        find you the best value in auto and home insurance.</p>
      <a class="gainbridge-protect-button yellow-button" :href="href">
        Let's go
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "GainbridgeProtect",
  components: {},
  props: ['href']
}
</script>

<style>
.gainbridge-protect-container {
  background-color: #020102;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: '100%'
}

.gainbridge-protect-content {
  padding: 60px 16px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-left: auto;
  margin-right: auto;
  max-width: 654px;
}

.gainbridge-protect-new {
  color: var(--black-l-5, #D6DCE8);
  text-align: center;

  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

.gainbridge-protect-header {
  color: var(--black-brand-white, #FFF);
  text-align: center;

  font-family: var(--font-family-aktiv_grotesk-normal);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gainbridge-protect-copy {
  color: var(--black-l-5, #D6DCE8);
  text-align: center;
  font-family: var(--font-family-aktiv_grotesk-normal);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}

.gainbridge-protect-button {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  width: fit-content;
  color: #000000;
  text-decoration-line: none;
}
</style>