import * as LDClient from "launchdarkly-js-client-sdk";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import "@/assets/styles/styleguide.css";
import "@/assets/styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "../../sentry";

Vue.config.productionTip = false;
const launchDarklyKey = process.env.LAUNCH_DARKLY_KEY;
if (launchDarklyKey) {
  const context = {
    kind: "user",
    key: "home",
  };
  const client = LDClient.initialize(launchDarklyKey, context);
  Vue.prototype.launchDarkly = client;
}

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
