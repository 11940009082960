<template>
  <div class="row row-risk-return-mygA">
    <ContentTextSize20
        :Title="titleText" :Content="contentText"
        class="col-lg-6  col-sm-12 order-risk-return"
    />

    <div class="steadypace-group col-lg-6  col-sm-12 ">
      <div class="content-image-container-home-left">
        <img
            class="risks_return_image-home"
            :src="srcImage"
            alt="group-pic"
        >
      </div>

      <div class="floater-left position-icon-image-left normalCorners">
        <div class="normalInnerContent">
          <img class="image-icon-2-home" :src="iconSrc" alt="image-icon-2">
          <dl>
            <dt>{{ iconTitle }}</dt>
            <dd>{{ iconBody }}</dd>
          </dl>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import ContentTextSize20 from "@/components/product/ContentTextSize20.vue";

export default  {
  name: 'LeftImageContent',
  components: {ContentTextSize20},
  props: ["titleText", "contentText", "srcImage", "iconTitle", "iconBody", "iconSrc"]
}
</script>
<style>


.row-risk-return-mygA {
  margin-top: 100px !important;
}

.steadypace-group {
  order: 2;
  align-items: flex-start;
  display: flex;
  /*height: 332.82px;*/
  /*left: 768px;*/
  /*min-width: 502px;*/
  position: relative;
  justify-content: flex-end;
  /*top: 1314px;*/
}



.risks_return_image-home {
  object-fit: cover;
  width: 100%;
  height: auto;
  float: right;
}


@media (max-width: 992px) {
  .risks_return_image-home {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  .row-risk-return-mygA {
    margin-top: 50px !important;
  }


}


.content-image-container-home-left {
  height: 502px;
  display: block;
  position: relative;
  flex-display: row;
  width: 398px;
  overflow: hidden;
  border-radius: 55px 3px;
}


@media (max-width: 991.98px) {
  .content-image-container-home-left {
    height: auto;
    width: 100%;

  }


  .row-risk-return-mobile {
    margin-top: 100px !important;
    justify-content: center;
  }

  .steadypace-group {
    order: 1;
    align-items: center;
    display: flex;
    /*left: 768px;*/
    /*min-width: 502px;*/
    position: relative;
    /*min-width: 300px;*/
    margin-top: 10px !important;
    margin-left: 0px !important;
    /*top: 1314px;*/
  }

}




/*######## floater ########*/


.floater-left {
  padding: 15px 0px 0px 15px;
  box-shadow: inset 0px 0px 10px 10px rgba(255, 255, 255, 0.5), 0px 5px 15px 3px rgba(0, 0, 0, 0.07);
  position: absolute;
  backdrop-filter: blur(5px);
  height: 104px!important;
  width: 289px!important;
}

.position-icon-image-1 {
  top: 60%;
  right: 40px;
}

.position-icon-image-left {
  bottom: 18px;
  right: 220px;
}

.floater-left.coolCorners {
  border-radius: 38px 3px;
}

.floater-left.normalCorners {
  border-radius: 3px 3px;
}

.floater-left .normalInnerContent {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px 0px 0px 15px;
  border-radius: 2px 2px;
  width: 259px!important;
  height: 74px!important;
}

.floater-left .innerContent {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px 0px 0px 15px;
  border-radius: 26px 2px;
  width: 239px!important;
  height: 74px!important;
}

.floater-left dl {
  margin-left: 60px;
  margin-top: 2px;
}


dt {
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--black-4);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 12px;
}

dl {
  white-space: nowrap;
  font-size: 14px;
  font-family: var(--font-family-aktiv_grotesk-regular);
  color: var(--black);
}

@media (max-width: 1198px) {
  .position-icon-image-left {
    bottom: 18px;
    right: 220px;
  }
}

@media (max-width: 998px) {
  .position-icon-image-left {
    bottom: -30px;
    right: unset;
    left: -30px;
  }
}

@media (max-width: 576px) {
  .position-icon-image-left {
    left: 10px;
  }
}



.image-icon-2-home {
  position: absolute;
  width: 45px;
  height: 45px;
}

</style>