<template>
  <div class="banner-logos-container">
    <span class="banner-logo">
      <Indy500Logo />
    </span>
    <span class="banner-logo">
      <ForbesLogo />
    </span>
    <span class="banner-logo">
      <RevracingLogo />
    </span>
    <span class="banner-logo">
      <YahooFinanceLogo />
    </span>
    <span class="banner-logo">
      <TheWallStreetJournalLogo />
    </span>
    <span class="banner-logo">
      <MarketWatchLogo />
    </span>
  </div>
</template>

<script>
import ForbesLogo from "./logos/ForbesLogo";
import Indy500Logo from "./logos/Indy500Logo";
import MarketWatchLogo from "./logos/MarketWatchLogo";
import RevracingLogo from "./logos/RevracingLogo";
import TheWallStreetJournalLogo from "./logos/TheWallStreetJournalLogo";
import YahooFinanceLogo from "./logos/YahooFinanceLogo";
export default {
  name: "BannerLogos",
  components: {
    Indy500Logo,
    ForbesLogo,
    RevracingLogo,
    YahooFinanceLogo,
    TheWallStreetJournalLogo,
    MarketWatchLogo,
  },
}
</script>

<style>
.banner-logos-container {
  padding: 0;
  background-color: #FAF7F4;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.banner-logo {
  padding: 30px 50px;
}
</style>