<template>
  <div class="homepage-footer-group-navigation">
    <div class="homepage-footer-nav-end">
      <div class="homepage-footer-nav-end">
        <div class="homepage-overlap-group">
          <div class="row">
            <product class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4" />
            <div class="homepage-explore-nav-end col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
              <div class="homepage-explore-1-nav-end aktivgrotesk-bold-black-14px">Resources</div>
              <frame2 children="Articles" />
              <div class="homepage-resources-nav-end"><a :href="faqLink" class="link">FAQ</a></div>
            </div>

            <company class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4" />
            <explore class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4" />
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
              <div class="homepage-socials-container">
                <a href="https://www.linkedin.com/company/gainbridge.life" class="homepage-socials-nav-end">
                  <img class="homepage-footer-socials-img" :src="require('@/assets/images/footer/Linkedin.png')"
                    alt="Linkedin" />
                </a>

                <a href="https://www.facebook.com/GainbridgeLife/" class="homepage-socials-nav-end">
                  <img class="homepage-footer-socials-img" :src="require('@/assets/images/footer/Facebook.png')"
                    alt="Facebook" />
                </a>

                <a href="https://twitter.com/GainbridgeLife" class="homepage-socials-nav-end">
                  <img class="homepage-footer-socials-img" :src="require('@/assets/images/footer/Twitter.png')"
                    alt="Twitter" />
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="foot-note-2">
              <ol class="homepage-disclosure-list">
                <li id="footnote-home-1">
                  Annual Percentage Yield (“APY”) rates are subject to change at any time, and the rate shown may no
                  longer be current.
                </li>
                <li id="footnote-home-2">
                  Source: Bankrate.com, national average of 5-year CD, week of 7/22/24. Rates subject to change.
                </li>
                <li id="footnote-home-3">
                  Provided your account value hasn't gone to zero due to excess withdrawals.
                </li>
                <li id="footnote-home-4">
                  <a href="https://news.ambest.com/newscontent.aspx?refnum=239737&altsrc=23" target="_blank"
                    class="text-action-blue-l4 text-link-x-small no-underline"
                    style="font-weight: bold; font-family: var(--font-family-aktiv_grotesk-bold)"> View details about AM
                    Best.</a>
                </li>
              </ol>
            </div>
          </div>

          <div class="homepage-divider-nav-end"></div>


          <div class="row">
            <p class="homepage-copyright col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">© 2023 Gainbridge Insurance
              Agency, LLC. All Rights Reserved.</p>

            <terms-privacy-cookies class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Company from "@/components/footer/Company.vue";
import Explore from "@/components/footer/Explore.vue";
import Frame2 from "@/components/footer/Frame2.vue";
import Product from "@/components/footer/Product.vue";
import TermsPrivacyCookies from "@/components/footer/TermsPrivacyCookies.vue";
export default {
  name: "Footer",
  components: {
    Product,
    Frame2,
    Company,
    Explore,
    TermsPrivacyCookies,
  },
  mounted() {
  },
  data() {
    return {
      faqLink: "faq.html",
    };
  },
  methods: {
  }
};
</script>

<style>
.homepage-footer-nav-end {
  left: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 1440px;
}

.homepage-footer-group-navigation {
  position: absolute;
  width: 100%;
  left: 0;
  justify-content: center;
  background-color: #f8f9f9;
  border: 1px solid;
  display: flex;
  border-color: var(--mercury);
}

.homepage-footer-nav-end {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  flex: 1;
}

.homepage-overlap-group {
  background-color: #f8f8f9;
  display: flex;
  height: fit-content;
  flex-direction: column;
  padding: 5% 7%;
  width: 100%;
}

.homepage-explore-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-bottom: 15px;
}

.homepage-explore-1-nav-end {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 0px;
  position: relative;
}

.homepage-resources-nav-end {
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: relative;

  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.homepage-socials-nav-end {
  position: relative;
  right: 0;
  padding: 8px;
}

.homepage-socials-container {
  position: absolute;
  right: -10px;
  padding-right: 7%;
  width: 260px;
  display: flex;
  justify-content: flex-end;
}

.homepage-divider-nav-end {
  background-color: var(--black);
  border: none;
  height: 1px;
  margin-top: 70px;
  opacity: 0.06;
  width: 100%;
  margin-bottom: 20px;
}

.homepage-copyright {
  color: var(--black);
  height: 20px;
  letter-spacing: 0;
  line-height: 20.2px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-family-aktiv_grotesk-regular);
}

.foot-note-2 {
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
  margin-bottom: 10px;
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 12px;
  color: var(--black);
}

.homepage-disclosure-list {
  margin-left: -20px;
  padding-left: 32px;
  list-style: decimal;
}

.foot-note-2 a {
  color: var(--black-l-4, #808080);
  font-family: var(--font-family-aktiv_grotesk-regular);
}

.alden-disclosure {
  font-size: 13px;
  font-weight: bold;
}

@media (max-width: 768px) {}

@media (max-width: 575.98px) {
  .homepage-copyright {
    color: var(--black);
    margin-top: 10px !important;
    margin-bottom: 10px;
    line-height: 20.2px;
    font-size: 11px;
  }


  .homepage-socials-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 0;
  }

  .foot-note-2 {
    margin-top: 70px !important;
  }
}

.homepage-footer-socials-img {
  width: 35px;
  height: 35px;
}
</style>