<template>
  <div class="row row-choose-adventure ">
    <div class="steadypace-group-1-home col-lg-6 col-sm-12">
      <div class="content-image-container-home">
        <img
            class="no-obstacles-image-part"
            :src="srcImage"
            alt="no-obstacles"
        >
      </div>

      <div class="floater position-icon-image-3 coolCorners">
        <div class="innerContent">
          <img class="image-icon-3-home" :src="iconSrc" alt="image-icon">
          <dl>
            <dt>{{ iconTitle }}</dt>
            <dd>{{ iconBody }}</dd>
          </dl>
        </div>
      </div>

    </div>

    <ContentText2
        :Title="titleText" :Content="contentText"
        class="col-lg-6 col-sm-12 order-risk-return"
    />
  </div>
</template>
<script>
import ContentText2 from "@/components/product/ContentText2.vue"

export default {
  name: 'RightImageContent',
  components: {ContentText2},
  props: ["titleText", "contentText", "srcImage", "iconTitle", "iconBody", "iconSrc"]

}
</script>
<style>


.steadypace-group-1-home {
  background-color: #ffffffcc;
  /*border-radius: 3px;*/
  /*box-shadow: 0px 4px 20px #02010226;*/
  display: flex;
  gap: 14px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  /*height: 615px;*/
  /*left: 172px;*/
  /*min-width: 498px;*/
  /*padding: 0 15px;*/
  position: relative;
  /*top: 1910px;*/
}


@media (min-width: 576px) and (max-width: 992px) {
}


.no-obstacles-image-part {
  object-fit: cover;
  width: 100%;
  height: auto;
  float: left;
}


@media (max-width: 992px) {

  .no-obstacles-image-part {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  .values-displayed-ar {
    width: 100% !important;
  }

  .row-choose-adventure {
    margin-top: 50px !important;
  }

}


.row-choose-adventure {
  margin-top: 100px !important;
}


.values-displayed-ar {
  color: var(--black-2);
  font-family: var(--font-family-aktiv_grotesk-thin);
  font-size: 8px;
  font-weight: 275;
  letter-spacing: 0;
  line-height: normal;
  text-align: end;
  position: relative;
  float: left;
  width: 92%;
  margin-top: 12px;

}

.content-image-container-home {
  height: 502px;
  display: block;
  position: relative;
  flex-display: row;
  width: 398px;
  overflow: hidden;
  border-radius: 3px;
}


@media (max-width: 991.98px) {
  .content-image-container-home{
    width: 100%;
    height: auto;
    padding: 11px;
  }

  .steadypace-group-1-home {
    align-items: center;
    display: flex;
  }

  .row-choose-adventure-mobile {
    margin-top: 100px !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 575.98px) {
  .steadypace-group-1-home {
    align-items: center;
    display: flex;
    order: 1;
  }

}



/*######## floater ########*/


.floater {
  padding: 15px 0px 0px 15px;
  box-shadow: inset 0px 0px 10px 10px rgba(255, 255, 255, 0.5), 0px 5px 15px 3px rgba(0, 0, 0, 0.07);
  position: absolute;
  backdrop-filter: blur(5px);
  height: 104px!important;
  width: 269px!important;
}

.position-icon-image-1 {
  top: 60%;
  right: 40px;
}

.position-icon-image-3 {
  bottom: 18px;
  left: 220px;
}

.position-icon-image-2 {
  top: 60%;
  left: 10%;
}

.floater.coolCorners {
  border-radius: 38px 3px;
}

.floater.normalCorners {
  border-radius: 3px 3px;
}

.floater .normalInnerContent {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px 0px 0px 15px;
  border-radius: 2px 2px;
  width: 239px!important;
  height: 74px!important;
}

.floater .innerContent {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 15px 0px 0px 15px;
  border-radius: 26px 2px;
  width: 239px!important;
  height: 74px!important;
}

.floater dl {
  margin-left: 60px;
  margin-top: 2px;
  /*margin-right: 20px;*/
  /*margin-bottom: 0px;*/
}

/*.floater dd {*/
/*  margin-bottom: 0px;*/
/*}*/

dt {
  white-space: nowrap;
  text-transform: uppercase;
  color: var(--black-4);
  font-size: 12px;
  font-family: var(--font-family-aktiv_grotesk-regular);
}

dl {
  font-family: var(--font-family-aktiv_grotesk-regular);
  white-space: nowrap;
  font-size: 14px;
  color: var(--black);
}

@media (max-width: 1198px) {
  .position-icon-image-3 {
    bottom: 18px;
    left: 180px;
  }
}

@media (max-width: 998px) {
  .position-icon-image-3 {
    bottom: -20px;
    left: unset;
    right: -30px;
  }
}

@media (max-width: 576px) {
  .position-icon-image-3 {
    right: 10px;
  }
}
</style>